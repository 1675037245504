import React from 'react'
import { Helmet } from 'react-helmet'
import { SEO as SEOTypes } from '@utils/types'

const SEO = (props: SEOTypes) => {
  const { lang = 'en', location } = props
  const metaDescription = props.description || 'Tyler Karu description'
  const metaTitle = props.title || 'Tyler Karu'
  const metaImage = props.image || ''
  const twitterCard = props.twitterCard || ''
  const twitterSite = props.twitterSite || ''
  const twitterCreator = props.twitterCreator || ''
  const metaKeywords = props.keywords || ''

  return (
    <Helmet>
      <html lang={lang} />
      <title>{metaTitle}</title>
      <link rel="canonical" href={location} />
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={metaKeywords} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:image" content={metaImage} />
      <meta name="twitter:card" content={twitterCard} />
      <meta name="twitter:site" content={twitterSite} />
      <meta name="twitter:creator" content={twitterCreator} />
    </Helmet>
  )
}

export default SEO
