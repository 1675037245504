import React from 'react'
import './index.scss'
import { graphql } from 'gatsby'
import SEO from '@components/seo'
import { SanityContactPage } from '@utils/types'
import { GatsbyImage } from 'gatsby-plugin-image'
import loadable from '@loadable/component'
const Form = loadable(() => import('@components/form'))

type Props = {
  data: {
    sanityContact: SanityContactPage
  }
  location: Location
}

const ContactPage = ({ data: { sanityContact }, location }: Props) => {
  const metadata = {
    location: location?.href,
    ...sanityContact?.seo,
  }

  return (
    <>
      <SEO {...metadata} />
      <div
        className={`contact-root sanity-bg-${sanityContact?.backgroundColor?.title.toLowerCase()}`}
      >
        <GatsbyImage
          className="contact-image"
          image={sanityContact?.image?.asset?.gatsbyImageData}
          alt={sanityContact?.image?.alt}
        />
        <div className="contact-form">
          <Form
            title={sanityContact?.title}
            subtitle={sanityContact?._rawSubtitle}
            email={sanityContact?.email}
          />
          <form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            hidden
          >
            <input name="firstName" hidden />
            <input name="email" hidden />
            <input type="checkbox" name="lastName" hidden />
            <input type="checkbox" name="residential" hidden />
            <input type="checkbox" name="commercial" hidden />
            <input type="checkbox" name="other_inquiry" hidden />
            <input name="timeline" hidden />
            <input name="minBudget" hidden />
            <input name="maxBudget" hidden />
            <input name="description" hidden />
          </form>
        </div>
      </div>
    </>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query {
    sanityContact(_id: { eq: "contactPage" }) {
      seo {
        description
        keywords
        image
        title
        twitterCard
        twitterCreator
        twitterSite
      }
      title
      _rawSubtitle(resolveReferences: { maxDepth: 10 })
      backgroundColor {
        value
        title
      }
      email
      id
      image {
        alt
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
`
